import { V3_BASE_URL } from '@appmaker/core/api/core';
import { useProject } from '@appmaker/core/hooks';
import axios from 'axios';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from '@appmaker/core/routes';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';

const CodePush = (props) => {
    const { projectId } = useProject()
    const [isLoading, setIsLoading] = useState(false);    
    const history = useHistory();
    const getRouterPath = useRouterPath();

    const onSyncCodePush = async () => {
        setIsLoading(true);
        const url = `${V3_BASE_URL}/codepush/${projectId}/set-project`;
        try {
            const response = await axios.post(url, {
                projectId
            }, {
                credentials: 'include',
                withCredentials: true,
            })
            if(response?.data && response?.data?.id){
                toast.success('CodePush sync completed successfully');
               
                history.push(getRouterPath('/extension/codepush/settings'));
            }
        } catch (error) {
            console.error('CodePush sync failed:', error);
            toast.error('Failed to sync CodePush. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <button
            onClick={onSyncCodePush}
            type="button"
            disabled={isLoading}
            className="inline-flex justify-self-end bg-green-600 border border-transparent rounded-md py-1 px-3 items-center justify-center text-base font-normal text-white hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
        >
            {isLoading ? (
                <>
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Syncing...
                </>
            ) : 'Sync CodePush'}
        </button>
    )
}

export default CodePush;