import appmaker from '@appmaker/core/index';
import CodePushHeader from './Codepush';

const CodePush = {
    id: 'codepush',
    name: 'Codepush',
    activate: () => {
        appmaker.addFilter('codepush-override-header-component-installed', 'extension-override', (override, meta) => (<CodePushHeader override={override} meta={meta} />))
    }
}

export default CodePush;